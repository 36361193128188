import React, { useContext } from "react";

import { faCheckCircle } from "@fortawesome/sharp-regular-svg-icons/faCheckCircle";
import { faMoneyBillTransfer } from "@fortawesome/sharp-regular-svg-icons/faMoneyBillTransfer";
import clsx from "clsx";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { formatCurrencyString } from "~utils/i18n_utils/formatCurrencyString";
import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";
import { IconFontAwesome } from "~components/IconFontAwesome";
import { StoryblokEditable } from "~components/StoryblokEditable";

import { AnimatedElement } from "~animations/animation_primitives/AnimatedElement";

import { PageQueryContext } from "~context/PageQueryContext";

import * as styles from "./styles.css";

import type { BoxProps } from "~components/Box";
import type { EnabledLanguageIsoCode } from "~config/ENABLED_LANGUAGES.config";

type IconMapKey = keyof typeof ICON_MAP;

const ICON_MAP = {
  faCheckCircle,
  faMoneyBillTransfer,
} as const;

export interface AnimLayerAlertProps extends BoxProps {
  icon?: IconMapKey;
  title?: string;
  description?: string;
  amount?: string | number;
  lang?: EnabledLanguageIsoCode;
}

export function AnimLayerAlert({
  icon = "faCheckCircle",
  title,
  className: userClassName,
  description,
  amount,
  ...rest
}: AnimLayerAlertProps) {
  const { pageContext } = useContext(PageQueryContext) || {};
  const { lang } = pageContext || {};

  const dynamicIcon = ICON_MAP[icon];

  return (
    <StoryblokEditable {...rest}>
      <AnimatedElement
        animationLevel="primary"
        maxWidth="gridSpan2"
        width="max-content"
        borderRadius="md"
        padding="phoneSpacing3"
        textAlign="center"
        className={clsx(userClassName, variantBackgroundColor.background_sand)}
        {...rest}
      >
        <IconFontAwesome
          icon={dynamicIcon}
          size="xl"
          color="white"
          className={styles.iconStyle}
          borderRadius="50%"
          /** Icon spacing */
          marginBottom="phoneSpacing2"
          marginX="auto"
          padding="phoneSpacing2"
          /** Icon size */
          width="phoneSpacing5"
          height="phoneSpacing5"
          data-motion={ANIMATED.secondary}
        />
        {title && (
          <Box
            textAppearance="phone_text_md"
            fontWeight="bold"
            marginBottom="phoneSpacing0"
            data-motion={ANIMATED.secondary}
          >
            {title}
          </Box>
        )}
        {description && (
          <Box data-motion={ANIMATED.secondary} textAppearance="phone_text_md">
            {description}
          </Box>
        )}

        {amount && (
          <Box data-motion={ANIMATED.secondary} textAppearance="phone_text_md">
            {formatCurrencyString({ amount, lang })}
          </Box>
        )}
      </AnimatedElement>
    </StoryblokEditable>
  );
}
